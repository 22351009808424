import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/analytics"
import "firebase/functions"
import "firebase/storage"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTey-_NJJSyqEYicMrRtViVYgfjhQ165M",
  authDomain: "the-sports-card-accountant.firebaseapp.com",
  projectId: "the-sports-card-accountant",
  storageBucket: "the-sports-card-accountant.appspot.com",
  messagingSenderId: "695311705610",
  appId: "1:695311705610:web:11e587c0815b169dd761f3",
  measurementId: "G-E2BDD0PZRX"
};

firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
export const analytics = firebase.analytics()
export const functions = firebase.functions()

export const usersCollection = db.collection("users")
