import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    light: true,
    themes: {
      light: {
        primary: '#3dadff',
        secondary: '#8beaff',
        tertiary: '#10316c',
        grey: '#dbdce2',
        offwhite: '#f6f6f7',
        menu: '#ffffff',
        error: '#FF5A57',
        red: '#FF5A57',
        yellow: '#FFD530',
        orange: '#FF824A',
        green: '#19B349',
        blue: '#3dadff',
        purple: '#FF4AF5',
        darkbg: '#10316c',
        darkblue: '#000e44',
        imgbg: '#9fbfd4',
      },
    },
  },
})
