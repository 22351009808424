import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase'
import firebase from 'firebase/app'
import storageHelper from 'storage-helper'

Vue.use(VueRouter)

var userClaims = {}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    beforeEnter(to, from, next) {
      document.title = `Home | ${process.env.VUE_APP_TITLE}`
      const _desc = `${process.env.VUE_APP_TITLE}`
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc)
      next()
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    beforeEnter(to, from, next) {
      document.title = `Privacy Policy | ${process.env.VUE_APP_TITLE}`
      const _desc = `${process.env.VUE_APP_TITLE}`
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc)
      next()
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import('@/views/TermsConditions.vue'),
    beforeEnter(to, from, next) {
      document.title = `Terms of Use | ${process.env.VUE_APP_TITLE}`
      const _desc = `${process.env.VUE_APP_TITLE}`
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc)
      next()
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/protected',
    name: 'Protected',
    component: () => import('@/views/Protected.vue'),
    beforeEnter: async (to, from, next) => {
      if (process.env.VUE_APP_ISPW !== 'T') {
        next('/')
      } else {
        next()
      }
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    beforeEnter(to, from, next) {
      document.title = `About | ${process.env.VUE_APP_TITLE}`
      const _desc = `About | ${process.env.VUE_APP_TITLE}`
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc)

      next()
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/product',
    name: 'ProductView',
    component: () => import('@/views/Product.vue'),
    beforeEnter(to, from, next) {
      document.title = `Product | ${process.env.VUE_APP_TITLE}`
      const _desc = `Product | ${process.env.VUE_APP_TITLE}`
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc)

      next()
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/pricing',
    name: 'PricingView',
    component: () => import('@/views/Pricing.vue'),
    beforeEnter(to, from, next) {
      document.title = `Pricing | ${process.env.VUE_APP_TITLE}`
      const _desc = `Pricing | ${process.env.VUE_APP_TITLE}`
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc)

      next()
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
    beforeEnter(to, from, next) {
      document.title = `Contact Us | ${process.env.VUE_APP_TITLE}`
      const _desc = `Contact Us | ${process.env.VUE_APP_TITLE}`
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc)

      next()
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('@/views/Blog.vue'),
    beforeEnter(to, from, next) {
      document.title = `Blog | ${process.env.VUE_APP_TITLE}`
      const _desc = `Blog | ${process.env.VUE_APP_TITLE}`
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc)

      next()
    },
    meta: {
      requiresPw: true,
    },
  },
  // {
  //   path: '/privacy-policy',
  //   name: 'PrivacyPolicy',
  //   component: () => import('@/views/PrivacyPolicy.vue'),
  // },
  // {
  //   path: '/terms-of-use',
  //   name: 'TermsOfUse',
  //   component: () => import('@/views/TermsOfUse.vue'),
  // },
  // {
  //   path: '/terms-of-service',
  //   name: 'TermsOfService',
  //   component: () => import('@/views/TermsOfService.vue'),
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login')
      } else {
        await firebase.getCurrentUser().then((user) => {
          if (user) {
            user.getIdTokenResult().then((claims) => {
              userClaims = claims.claims
            })
          }
        })

        if (userClaims.subscribed) {
          document.title = `Dashboard | ${process.env.VUE_APP_TITLE}`
          const _desc = `Dashboard | ${process.env.VUE_APP_TITLE}`
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', _desc)

          next()
        } else if (!userClaims.subscribed) {
          next('/signup')
        } else {
          next('/login')
        }
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/financials/pdf',
    name: 'FinancialPdf',
    component: () => import('@/views/FinancialPdf.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login')
      } else {
        await firebase.getCurrentUser().then((user) => {
          if (user) {
            user.getIdTokenResult().then((claims) => {
              userClaims = claims.claims
            })
          }
        })

        if (userClaims.subscribed) {
          document.title = `Finanacials | ${process.env.VUE_APP_TITLE}`
          const _desc = `Finanacials | ${process.env.VUE_APP_TITLE}`
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', _desc)

          next()
        } else if (!userClaims.subscribed) {
          next('/signup')
        } else {
          next('/login')
        }
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/views/Billing.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login')
      } else {
        await firebase.getCurrentUser().then((user) => {
          if (user) {
            user.getIdTokenResult().then((claims) => {
              userClaims = claims.claims
            })
          }
        })

        if (userClaims.subscribed) {
          document.title = `Billing | ${process.env.VUE_APP_TITLE}`
          const _desc = `Billing | ${process.env.VUE_APP_TITLE}`
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', _desc)

          next()
        } else if (!userClaims.subscribed) {
          next('/signup')
        } else {
          next('/login')
        }
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('@/views/Inventory.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login')
      } else {
        await firebase.getCurrentUser().then((user) => {
          if (user) {
            user.getIdTokenResult().then((claims) => {
              userClaims = claims.claims
            })
          }
        })

        if (userClaims.subscribed) {
          document.title = `Inventory | ${process.env.VUE_APP_TITLE}`
          const _desc = `Inventory | ${process.env.VUE_APP_TITLE}`
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', _desc)

          next()
        } else if (!userClaims.subscribed) {
          next('/signup')
        } else {
          next('/login')
        }
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/import',
    name: 'import',
    component: () => import('@/views/Import.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login')
      } else {
        await firebase.getCurrentUser().then((user) => {
          if (user) {
            user.getIdTokenResult().then((claims) => {
              userClaims = claims.claims
            })
          }
        })

        if (userClaims.subscribed) {
          document.title = `Import | ${process.env.VUE_APP_TITLE}`
          const _desc = `Import | ${process.env.VUE_APP_TITLE}`
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', _desc)

          next()
        } else if (!userClaims.subscribed) {
          next('/signup')
        } else {
          next('/login')
        }
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  // {
  //   path: '/subscriptions',
  //   name: 'subscriptions',
  //   component: () => import('@/views/Subscriptions.vue'),
  //   beforeEnter: async (to, from, next) => {
  //     if (!auth.currentUser) {
  //       next('/login')
  //     } else {
  //       await firebase.getCurrentUser().then((user) => {
  //         if (user) {
  //           user.getIdTokenResult().then((claims) => {
  //             userClaims = claims.claims
  //           })
  //         }
  //       })

  //       if (userClaims.subscribed) {
  //         document.title = `Subscription | ${process.env.VUE_APP_TITLE}`
  //         const _desc = `Subscription | ${process.env.VUE_APP_TITLE}`
  //         document
  //           .querySelector('meta[name="description"]')
  //           .setAttribute('content', _desc)

  //         next()
  //       } else if (!userClaims.subscribed) {
  //         next('/signup')
  //       } else {
  //         next('/login')
  //       }
  //     }
  //   },
  //   meta: {
  //     requiresPw: true,
  //   },
  // },
  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/Success.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login')
      } else {
        document.title = `Success | ${process.env.VUE_APP_TITLE}`
        const _desc = `Success | ${process.env.VUE_APP_TITLE}`
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc)

        next()
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/canceled',
    name: 'canceled',
    component: () => import('@/views/Canceled.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login')
      } else {
        document.title = `Canceled | ${process.env.VUE_APP_TITLE}`
        const _desc = `Canceled | ${process.env.VUE_APP_TITLE}`
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc)

        next()
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Admin/AdminDashboard.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims
          })
        }
      })

      if (!auth.currentUser) {
        next('/login')
      } else if (userClaims.admin) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/admin/pages',
    name: 'Page',
    component: () => import('@/views/Admin/Pages.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims
          })
        }
      })

      if (!auth.currentUser) {
        next('/login')
      } else if (userClaims.admin) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/admin/posts',
    name: 'AdminPosts',
    component: () => import('@/views/Admin/Posts.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims
          })
        }
      })

      if (!auth.currentUser) {
        next('/login')
      } else if (userClaims.admin) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/admin/pages/edit/:pageId',
    name: 'EditPageView',
    component: () => import('@/views/Admin/EditPageView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims
          })
        }
      })

      if (!auth.currentUser) {
        next('/login')
      } else if (userClaims.admin) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/admin/posts/edit/:postLink',
    name: 'EditPosts',
    component: () => import('@/views/Admin/EditPost.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims
          })
        }
      })

      if (!auth.currentUser) {
        next('/login')
      } else if (userClaims.admin) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/admin/posts/preview/:postLink',
    name: 'Posts',
    component: () => import('@/views/Admin/PostPreview.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims
          })
        }
      })

      if (!auth.currentUser) {
        next('/login')
      } else if (userClaims.admin) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/Signup.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims
          })
        }
      })

      if (auth.currentUser && userClaims.subscribed) {
        next('/dashboard')
      } else {
        document.title = `Sign Up | ${process.env.VUE_APP_TITLE}`
        const _desc = `Sign Up | ${process.env.VUE_APP_TITLE}`
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc)

        next()
      }
    },
    meta: {
      requiresPw: true,
    },
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims
          })
        }
      })
      if (auth.currentUser && userClaims.admin) {
        next('/admin/dashboard')
      } else if (auth.currentUser && userClaims.subscribed) {
        next('/dashboard')
      } else if (auth.currentUser && !userClaims.subscribed) {
        next('/signup')
      } else {
        document.title = `Login | ${process.env.VUE_APP_TITLE}`
        const _desc = `Login | ${process.env.VUE_APP_TITLE}`
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc)

        next()
      }
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '/blog/:postLink',
    name: 'Post',
    component: () => import('../views/Post.vue'),
    beforeEnter(to, from, next) {
      document.title = `${process.env.VUE_APP_TITLE}`
      const _desc = `${process.env.VUE_APP_TITLE}`
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc)
      next()
    },
    meta: {
      requiresPw: true,
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      requiresPw: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

router.beforeEach((to, from, next) => {
  if (to.meta.requiresPw && process.env.VUE_APP_ISPW == 'T') {
    if (!storageHelper.getItem('user-password')) {
      next('/protected')
    } else if (
      storageHelper.getItem('user-password') !== process.env.VUE_APP_PW
    ) {
      next('/protected')
    } else next()
  } else next()
})

export default router
