<template>
  <v-app>
    <component :user="user" :is="layout">
      <router-view :user="user" :layout.sync="layout" />
      <v-snackbar
        v-model="upgradeDialog"
        :multi-line="true"
        timeout="-1"
        :verticle="true"
        color="primary"
        top
        transition="scroll-y-transition"
      >
        {{ upgradeMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" outlined v-bind="attrs" @click="upgradeDialog = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </component>
  </v-app>
</template>

<script>
import * as fb from "@/firebase";
import { eventBus } from "@/main.js";
import "@/app.css";

export default {
  name: "App",

  components: {},

  data: () => ({
    layout: `div`,
    user: null,
    authUser: null,
    isAdmin: false,
    upgradeDialog: false,
    upgradeMessage: "",
    inventory: [],
  }),
  watch: {
    authUser: {
      immediate: true,
      async handler() {
        if (this.authUser) {
          await fb.auth.currentUser.getIdTokenResult().then((claims) => {
            let userClaims = claims.claims;
            this.isAdmin = userClaims.admin ? userClaims.admin : false;
          });

          await this.$bind(
            "inventory",
            fb.db.collection(`users/${this.authUser.id}/inventory`).limit(10)
          );

          const isPro = this.authUser.subscription == "Pro Plan" ? true : false;

          const isBasic = this.authUser.subscription == "Basic Plan" ? true : false;

          const isFree = this.authUser.subscription == "Free Trial" ? true : false;
          this.user = {
            ...this.authUser,
            isAdmin: this.isAdmin,
            id: this.authUser.id,
            isFree: isFree,
            isBasic: isBasic,
            isPro: isPro,
            eventBus: eventBus,
            maxInventory: this.inventory.length >= 10 ? true : false,
          };
          // console.log(this.user)
        }
      },
    },
    inventory: {
      immediate: true,
      async handler() {
        if (this.user) {
          if (this.inventory.length >= 10) {
            this.user.maxInventory = true;
          } else {
            this.user.maxInventory = false;
          }
        }
      },
    },
  },
  async created() {
    eventBus.$on("upgradePlan", (val) => {
      this.upgradeMessage = `${val}`;
      this.upgradeDialog = true;
    });

    if (fb.auth.currentUser) {
      await this.$bind("authUser", fb.usersCollection.doc(fb.auth.currentUser.uid));
    }
  },
};
</script>
