import VueMq from 'vue-mq'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { auth } from './firebase'
import { firestorePlugin } from 'vuefire'
import VueMask from 'v-mask'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueHtml2pdf from 'vue-html2pdf'
Vue.use(VueMask)
Vue.use(VueHtml2pdf)

Vue.use(firestorePlugin)
Vue.use(CKEditor)

Vue.config.productionTip = false
Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity,
  },
})
export const eventBus = new Vue()

var debounceTimeout
const DebounceDueTime = 1000
auth.onAuthStateChanged(() => {
  if (debounceTimeout) clearTimeout(debounceTimeout)

  debounceTimeout = setTimeout(() => {
    debounceTimeout = null

    new Vue({
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app')
  }, DebounceDueTime)
})
